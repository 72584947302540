import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

export const getPosts = graphql`
  query TeachSidebar {
    posts: allContentfulBlog(
      limit: 10
      filter: { pageType: { eq: "Teaching" } }
    ) {
      edges {
        node {
          id
          title
          date(formatString: "DD MMM")
          slug
          pageType
          featuredImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const TeachingSidebar = () => {
  const { posts } = useStaticQuery(getPosts);

  return (
    <div className="bg-gray-100 p-6 rounded-xl flex-1">
      <h3 className="flex justify-center pb-4">Latest Teaching</h3>
      <ul className="flex flex-col gap-2">
        {posts.edges.map(({ node }) => (
          <li className="hover:bg-white rounded-lg flex flex-row p-2">
            <div className="w-16">
              <img
                src={node.featuredImage.file.url}
                alt={node.title}
                className="rounded-full object-cover h-16 w-16 border-2 border-gray-300"
              />
            </div>

            <div className="text-left pl-4 pt-4">
              <Link
                to={`/${node.pageType.toLowerCase()}/${node.slug}`}
                className="text-lg text-bold text-gray-900 hover:text-yellow-500"
              >
                {node.title}
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TeachingSidebar;
